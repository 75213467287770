<template>
  <div class="foot w-1920">
    <div class="list w-1200">
      <router-link class="spen" tag="div" :to="{ name: 'Home' }">网站首页</router-link>
      <div class="img">
        <img src="../../assets/login/jiange.gif" alt="" />
      </div>
      <router-link class="spen" tag="div" :to="{ name: 'helpCenter1' }">服务中心</router-link>
      <div class="img">
        <img src="../../assets/login/jiange.gif" alt="" />
      </div>
      <!-- <div class="spen">购物流程</div>
      <div class="img">
        <img src="../../assets/login/jiange.gif" alt="" />
      </div> -->
      <router-link class="spen" tag="div" :to="{ name: 'helpCenter1',params: {id: 12,} }">退款说明</router-link>
      <div class="img">
        <img src="../../assets/login/jiange.gif" alt="" />
      </div>
      <router-link class="spen" tag="div" :to="{ name: 'helpCenter1',params: {id: 17,} }">法律声明</router-link>
      <!-- <div class="spen">退款流程</div>
      <div class="img">
        <img src="../../assets/login/jiange.gif" alt="" />
      </div> -->
      <!-- <div class="img">
        <img src="../../assets/login/jiange.gif" alt="" />
      </div>
      <div class="spen">退款政策</div> -->
    </div>
    <div class="shengming w-1200">
      德洋（山东）化学有限公司 | 版权所有 | 并保留所有权利 | <a style="text-decoration: none;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37012602000129" target="_blank">鲁公网安备37012602000129号</a> |
      <a style="text-decoration: none;" href="https://beian.miit.gov.cn" target="_blank">备案号:鲁ICP备19059784号-2号</a>
    </div>
    <!-- <div class="bottom">
      <ul class="lie">
        <li>
          <img src="../../assets/login/logo-1.png" alt="" />
          <div class="spen">经营性网站备案信息</div>
        </li>
        <li>
          <img src="../../assets/login/logo-2.png" alt="" />
          <div class="spen">可信网站信用评估</div>
        </li>
        <li>
          <img src="../../assets/login/logo-3.png" alt="" />
          <div class="spen">诚信网站</div>
        </li>
        <li>
          <img src="../../assets/login/logo-4.png" alt="" />
          <div class="spen">网络警察</div>
        </li>
        <li>
          <img src="../../assets/login/logo-5.png" alt="" />
          <div class="spen">网购大家评</div>
        </li>
        <li>
          <img src="../../assets/login/logo-6.png" alt="" />
          <div class="spen">网上有害信息举报专区</div>
        </li>
      </ul>
    </div> -->
  </div>
</template>
<script>
  export default {
    methods: {
      ruzhu() {
        this.$message.warning({
          message: '请先登录账号',
        });
      },
    },
    handleMenuClick(id) {
      this.$router.tp({
        name: "helpCenter",
        params: {
          id: id,
        }
      });
    }
  };
</script>
<style lang="scss" scoped>
  .foot {
    border-top: 3px solid #f5f5f5;
    width: 100%;

    .list {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      padding-right: 50px;

      .spen {
        color: #2c2a2b;
        font-size: 12px;
        margin-left: 10px;
        cursor: pointer;
      }

      .img {
        width: 2px;
        height: 14px;
        margin-left: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .shengming {
      height: 50px;
      line-height: 50px;
      color: #7c7c7c;
      font-size: 12px;
      text-align: center;
      padding-right: 43px;
    }

    .bottom {
      .lie {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-left: 25px;
          display: flex;
          align-items: center;

          img {
            width: 35px;
            height: 35px;
          }

          .spen {
            font-size: 12px;
            color: #98999b;
            width: 100px;
            margin-left: 10px;
          }
        }
      }
    }
  }
</style>

<template>
  <div>
    <Header />
    <div class="logo">
      <div class="logopic">
        <div class="logopic-left">
          <img @click="home" src="../../assets/login/logo.gif" alt="" />
          <div class="zhuce">欢迎注册</div>
        </div>
        <div class="logopic-right">
          <div class="logopic-right_L">已有账号？</div>
          <router-link
            :to="{
              name: 'Login',
            }"
            tag="div"
            class="logopic-right_R"
            >请登录></router-link
          >
        </div>
      </div>
    </div>
    <div class="conter">
      <div class="buzhou">
        <svg class="logo-search-progress">
          <image
            x="0"
            y="30"
            width="130"
            height="6"
            xlink:href="../../assets/settlement/progress-02.png"
          ></image>
          <image
            x="129"
            y="30"
            width="130"
            height="6"
            xlink:href="../../assets/settlement/progress-02.png"
          ></image>
          <image
            x="259"
            y="30"
            width="130"
            height="6"
            xlink:href="../../assets/settlement/progress-03.png"
          ></image>
          <image
            x="50"
            y="8"
            width="27"
            height="38"
            xlink:href="../../assets/settlement/progress-icon-02.png"
          ></image>
          <image
            x="180"
            y="8"
            width="27"
            height="38"
            xlink:href="../../assets/settlement/progress-icon-02.png"
          ></image>
          <image
            x="310"
            y="8"
            width="27"
            height="38"
            xlink:href="../../assets/settlement/progress-icon-03.png"
          ></image>
          <text x="60" y="38" style="fill:#fff;">1</text>
          <text x="190" y="38" style="fill:#fff;">2</text>
          <text x="320" y="38" style="fill:#fff;">3</text>
          <text x="25" y="58" style="fill:#ff902a;">验证手机号</text>
          <text x="150" y="58" style="fill:#ff902a;">填写账号信息</text>
          <text x="295" y="58" style="fill:#dedede;">注册成功</text>
        </svg>
      </div>
      <router-link :to="{ name: 'Register_succed' }" tag="div" class="tiaoguo"
        >跳过此步骤</router-link
      >
      <div class="success">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio-group v-model="form.resource">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="年龄">
            <el-date-picker
              v-model="form.birth"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Login_bottom />
    <el-button :plain="true" v-show="false"></el-button>
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import Login_bottom from '@/components/login/login_bottom.vue';
import { post, get } from '@/utils/request';
export default {
  components: {
    Login_bottom,
    Header,
  },
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '男',
        desc: '',
        birth: '',
      },
      list: [],
      phone:''
    };
  },
  created() {
    // this.getorder();
    this.phone= this.$route.params.phone
    // 根据注册手机号获取会员信息
    this.getMemberByPhone(this.phone);
  },
  methods: {
    home() {
      this.$router.push({
        name: 'Home',
      });
    },
    // getorder() {
    //   get('api/member/selMemberInfo').then((res) => {
    //     this.list = res.data.data;
    //   });
    // },
    /**
     *根据注册手机号获取会员ID
     * @param mobile
     */
    getMemberByPhone(mobile){
      get('f/api/register/getRegisterMember?mobile='+mobile).then((res) => {
        this.list = res.data.data;
      });
    },
    onSubmit() {
      post('f/api/register/addSave', {
        memberId: this.list.memberId,
        nickName: this.form.name,
        headPhoto: '11',
        gender: this.form.resource,
        birth: this.form.birth,
      }).then((res) => {
        if (res.data.msg == '操作成功') {
          this.$message.success({
            message: '操作成功',
          });
          this.$router.push({
            name: 'Register_succed',
          });
          // this.getorder();
        } else {
          this.$message.error({
            message: '操作失败',
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 100%;
  height: 110px;
  border-bottom: 3px solid #f5f5f5;
  .logopic {
    width: 1234px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      img {
        width: 158px;
        height: 44px;
        margin-left: 60px;
        cursor: pointer;
      }
      .zhuce {
        font-size: 28px;
        color: #245af2;
        font-weight: 600;
        margin-left: 27px;
      }
    }
    &-right {
      height: 100px;
      font-size: 15px;
      display: flex;
      align-items: flex-end;
      &_L {
        color: #9a9a9a;
      }
      &_R {
        color: #f14f44;
        margin-left: 17px;
        cursor: pointer;
      }
    }
  }
}

.conter {
  padding-bottom: 100px;
  width: 100%;
  .buzhou {
    width: 402px;
    margin: auto;
    margin-top: 50px;
    .logo-search-progress {
      width: 100%;
      height: 104px;
    }
  }
  .tiaoguo {
    width: 1000px;
    margin: 20px auto;
    color: #245af2;
    cursor: pointer;
  }
  .success {
    width: 900px;
    margin: 50px auto;
  }
}
</style>
